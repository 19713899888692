export const pluralizeString = (value: number, state: string[]) => {
  const lastDigit = value % 10
  if (value >= 11 && value <= 20) {
    return state[0]
  } else if (lastDigit === 1) {
    return state[1]
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return state[2]
  } else {
    return state[0]
  }
}