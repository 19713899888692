<template>
  <div class="deal-boxes__box-deal">
    <span
      style="margin-bottom: 0"
      class="deal-boxes__container-head-data deal-boxes__container-head"
    >
      <h2 class="page__head-boxTitle no-margin">Презентация</h2>
      <section v-if="!PresentationData.presentation" class="presentation__dealblock-noobjs">
        <p class="deal-boxes__box-pale">
          Создайте презентацию для клиента с подходящими ему предложениями, добавив объекты на
          странице подбора
        </p>
        <base-butt
          @click="$emit('butt_click')"
          :text="$props.butt_text"
          type="1"
          wh="100%"
          he="48px"
        />
      </section>
      <section v-else class="presentation__dealblock-info">
        <div class="top-block">
          <p class="date">
            {{ formatTimeDaysAgo(PresentationData.presentation.created_at, true) }}
          </p>
          <button @click="openHistory" class="butt">
            <base-icon name="history" size="20px" color="var(--slate-400)" />
            <q-tooltip
              transition-show="jump-right"
              transition-hide="jump-left"
              anchor="center end"
              self="center left"
              >Открыть историю</q-tooltip
            >
            <history-display :presentationID="PresentationData.presentation.id" />
          </button>
        </div>
        <div class="counter-block">
          <span class="counter-block-text">
            <p class="title">
              {{ PresentationData.presentation.counters.records_to_watching_count }}
            </p>
            <p class="subtitle">
              {{
                pluralizeString(PresentationData.presentation.records_to_watching_count, [
                  'записей',
                  'запись',
                  'записи',
                ])
              }}
            </p>
          </span>
          <q-separator vertical />
          <span class="counter-block-text">
            <p class="title">
              {{ PresentationData.presentation.counters.objects_count }}
            </p>
            <p class="subtitle">
              {{
                pluralizeString(PresentationData.presentation.counters.objects_count, [
                  'объектов',
                  'объект',
                  'объекта',
                ])
              }}
            </p>
          </span>
          <q-separator vertical />

          <span class="counter-block-text">
            <p class="title">
              {{ PresentationData.presentation.counters.views_count }}
            </p>
            <p class="subtitle">
              {{
                pluralizeString(PresentationData.presentation.counters.views_count, [
                  'просмотров',
                  'просмотр',
                  'просмотра',
                ])
              }}
            </p>
          </span>
        </div>
        <div class="account__input-item account__input-item-flex">
          <div class="get-agent-data">
            <p class="text" @copy="handleHandsCopy" @click="handleLinkClick">
              agentum.pro/p/sdvf-sdfg
            </p>
            <base-icon-butt @click="copyToClipboard">
              <base-icon style="cursor: pointer" name="copy" size="20px" color="var(--slate-400)" />
              <q-tooltip
                transition-show="jump-right"
                transition-hide="jump-left"
                anchor="center end"
                self="center left"
                >{{ isCopied ? 'Скопировано!' : 'Копировать' }}</q-tooltip
              >
            </base-icon-butt>
          </div>
        </div>
      </section>
    </span>
  </div>
</template>

<script setup lang="ts">
import { dealStore } from '~/store/dealStore'
import { formatTimeDaysAgo } from '~/helpers/Dates/formatTime'
import { pluralizeString } from '~/helpers/strings/pluralizeString'

const props = defineProps({
  butt_text: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['butt_click'])

const StoreDeal = dealStore()
const router = useRouter()

const isCopied = ref(false)
const PresentationData = computed(() => StoreDeal.$state.currentDealData)

const copyToClipboard = () => {
  navigator.clipboard.writeText(defineRouteURL())
  isCopied.value = true
  setTimeout(() => {
    isCopied.value = false
  }, 700)
}

const openHistory = () => {}

const defineRouteURL = () => {
  const routeData = router.resolve({
    path: `/presentation/${PresentationData.value.presentation.code}`,
  })
  const fullURL = window.location.origin + routeData.href
  return fullURL
}

const handleHandsCopy = () => {
  navigator.clipboard.writeText(defineRouteURL())
}

const handleLinkClick = () => {
  let routeData = router.resolve({
    path: `/presentation/${PresentationData.value.presentation.code}`,
  })

  window.open(routeData.href, '_blank')
}
</script>

<style scoped lang="scss">
.deal-boxes__container-head-data {
  width: 100%;
}
.presentation {
  &__dealblock {
    &-info {
      display: grid;
      gap: 25px;
      .butt {
        position: absolute;
        top: 28px;
        right: 32px;
        display: flex;
        width: 40px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(--slate-200);
        background: var(--white, #fff);
        cursor: pointer;
      }
      .date {
        color: var(--slate-400);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 0;
        margin-top: -10px;
      }
      .top-block {
        display: flex;
      }
      .counter-block {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 26px;
        &-text {
          font-style: normal;
          font-weight: 400;
          color: var(--slate-800);
          display: grid;
          .title {
            font-size: 28px;
            line-height: 28px;
            margin: 0;
          }
          .subtitle {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
          }
        }
      }
    }
    &-counter {
      width: 100%;
      height: 48px;
      cursor: pointer;
    }
    &-noobjs {
      display: grid;
      gap: 24px;
    }
  }
}
.get-agent-data {
  display: flex;
  padding: 13px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: var(--slate-100);
  gap: 50px;
  margin-top: 7px;

  .text {
    color: var(--violet-400);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
    margin: 0;
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }
}
</style>
